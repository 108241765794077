/* font family  */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* ------------------------------------------------------------------------------------------------- */
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  text-decoration: none;
}

.screen-wrapper {
  width: 100%;
  max-width: 428px;
  padding: 10px 16px;
  color: #FFFF;
  background-color: #1C50D0;
}

.logo-wrapper {
  padding: 10px 16px;
}

.logo-wrapper img {
  width: 136px;
  height: 22px;
}

.payment-back-block {
  border-bottom: 1px solid #3C73DA;
  padding: 10px 16px;
  background-color: #1C50D0;
  max-width: 428px;
  padding-bottom: 24px;
}

.payment-back-block .payment-text {
  color: #FFF;
  font-size: 18px;
  font-weight: 500;
}

.amount-wrapper {
  /* padding:32px 136px 16px; */
  padding-top: 32px;
  background-color: #1C50D0;

}

.upi-click {
  margin-bottom: 50px;
}

.payment-method-wrapper {
  background-color: #FFFF;
  box-shadow: 0px 1px 5px #0006;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: -80px;
  margin-top: 40px;
  position: relative;
}

.payment-upi {
  border-bottom: 1px dashed #BFBFBF;
  padding-bottom: 15px;
}

.payment-method .payment-name h5 {
  color: #1C50D0;
}

.payment-method .payment-name span {
  color: #656565;
}

.important-note-wrapper {
  padding: 30px 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #FAFAFA;
  width: 100%;
  max-width: 428px;
  margin-top: -5px;
}

.important-note-wrapper .important-note-block {
  padding-top: 80px;
}

.important-note-wrapper .important-note-block h5 {
  font-size: 20px;
  font-weight: 500;
  color: #3F3F3F;
  margin-bottom: 25px;
}

.important-note-box {
  box-shadow: 0px 1px 5px #0006;
  padding: 20px 24px;
  border-radius: 12px;
}

.important-note-box .note-box {
  border-bottom: 1px dashed #BFBFBF;
  padding-bottom: 10px;
}

.important-note-box .note-box:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.important-note-box .note-box h5 {
  color: #3F3F3F;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.secure-payment-wrapper h5 {
  margin-top: 40px;
  margin-bottom: 24px;
  color: #3F3F3F;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.cancle-payment-btn-block button {
  background: #FFDDDD;
  color: #BC3434;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 12px;
  padding: 22px 120px;
  outline: 0;
  border: 0;
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-top: 25px;
}

.process-button-block button {
  padding: 22px 80px;
  background: #1C50D0;
  color: #FFF;
  margin-bottom: 20px;
}

.proccesing-time-block h5 {
  font-size: 14px;
  color: #656565;
}

.proccesing-time-block span {
  color: #383838;
  font-size: 18px;
  font-weight: medium;
}

.after-payment-block h5 {
  color: #383838;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin: 24px 0 15px 0;
  font-family: "Poppins";
}

.after-payment-block .after-payment-form input,
.transaction-id-wrapper input {
  padding: 22px 15px;
  width: 70%;
  /* border: 1px solid #DDD; */
  border-radius: 12px;
  background: #F0F2F5;
  border: 0;
}

.transaction-id-wrapper input {
  width: 100%;
}

.transaction-id-wrapper {
  margin-top: 25px;
}

.transaction-id-wrapper h5 {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #3F3F3F;
}

.bank-detail-wrapper .transaction-important-note .transaction-important-note-title {
  color: #3F3F3F;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.after-payment-block .after-payment-form input::placeholder,
.transaction-id-wrapper input::placeholder {
  color: #656565;
}

.after-payment-block .after-payment-form .payment-btn {
  padding: 20px 15px;
  border: 0;
  border-radius: 12px;
  background: #1C50D0;
  color: #FFF;
  font-size: 14px;
  font-weight: bold;
}

.scan-qr-wrapper {
  padding: 30px 50px;
  box-shadow: 0px 1px 5px #0006;
  border-radius: 12px;
  margin-top: 24px;
  position: relative;
  background: #FFF;
}

.scan-qr-wrapper h5 {
  color: #1C50D0;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.scan-2-wrapper {
  padding-top: 100px;
}

.qr-scan-two-wrapper {
  margin-top: -100px;
}

.important-note-wrapper-two .important-note-box {
  margin-top: -120px;
  position: relative;
  background: #FFF;
}

.important-note {
  color: #FFF;
  font-size: 20px;
  font-weight: bold;
}

.important-two-wrapper {
  padding-bottom: 100px;
}

.important-two-wrapper p {
  color: #A6C0FF;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins";
}

.bank-detail-wrapper .bank-dec {
  font-size: 16px;
  color: #3F3F3F;
  text-align: center;
  line-height: 26px;
}

/* .bank-detail-wrapper{
  border-radius: 12px;
  box-shadow: 0px 1px 5px #0006;
  padding: 15px;
} */
.bank-detail-wrapper .bank-detail-group span {
  color: #656565;
  font-size: 12px;
}

.bank-detail-wrapper .bank-detail-group {
  border-bottom: 1px dashed #BFBFBF;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.bank-detail-wrapper .bank-detail-group h5 {
  color: #1C50D0;
  font-size: 18px;
  font-weight: medium;
}

.bank-wrapper .bank-amont-dtl {
  padding-bottom: 100px;
}

.bank-des-wrap {
  margin-top: -90px;
  background: #FFF;
  border-radius: 12px;
  box-shadow: 0px 1px 5px #0006;
  padding: 15px;
}

.something-wrong-wrapper {
  padding-bottom: 100px;
}

.something-wrong-box {
  background-color: #FFF;
}

.something-wrong-box .wrong-text {
  color: #BC3434;
  font-size: 20px;
  font-weight: 500;
  margin-top: 32px;
  margin-bottom: 12px;
}

.something-wrong-box .wrong-dec {
  font-size: 16px;
  font-weight: 500;
  color: #656565;
  line-height: 24px;
}

.something-wrong-box {
  margin-top: -90px;
  background: #FFF;
  border-radius: 12px;
  box-shadow: 0px 1px 5px #0006;
  padding: 30px;
}

.something-wrong-box .order-id {
  color: #1C50D0;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 16px;
}

.something-wrong-box .transection-id {
  color: #3F3F3F;
  font-size: 16px;
}

.success-text {
  color: #02BE24;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 12px;
  font-weight: 500;
}

.loading-wrapper{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}